<template>
  <div id="browser">
    <div class="content" style="background-color: white;">
      <div class="mac" v-if="$route.query.id == 4">
        <img class="load_jpg" :src="load_jpg" alt="" />
        <span style="color: green;">后台适配安卓版app</span>
        <span style="color: green;">(请打开微信扫一扫)</span>
      </div>
      <div class="mac" v-else-if="$route.query.id == 6">
        <img class="load_jpg" :src="parent_icon" alt="" />
        <span style="color: green;">家长端</span>
        <span style="color: green;">(请打开微信扫一扫)</span>
      </div>
      <div class="mac" v-else-if="$route.query.id == 5">
        <img class="load_jpg" :src="teacher_icon" alt="" />
        <span style="color: green;">教师端</span>
        <span style="color: green;">(请打开微信扫一扫)</span>
      </div>
    </div>
  </div>
</template>

<script>
import load_jpg from "@/assets/image/download/code.jpg";
import parent_icon from "@/assets/image/download/parent_icon.png";
import teacher_icon from "@/assets/image/download/teacher_icon.png";
export default {
  data() {
    return {
      load_jpg,
      parent_icon,
      teacher_icon
    };
  },

  methods: {},
};
</script>

<style lang="stylus" scoped>
#browser
  .content
    box-sizing border-box
    padding 200px 200px
    margin-top 77px
    height 800px
    display flex
    flex-flow row nowrap
    justify-content center
    background #f7f7f7
    .mac
      margin 0 10%
      border-radius 10px
      display flex
      flex-flow column nowrap
      justify-content center
      align-items center
      font-weight 700
      color #606266
      cursor pointer
      transition all .5s ease
      .load_jpg
        width 300px
        height 300px
        margin-bottom 20px
</style>
